import { useEffect, useState } from "react";
import axios from 'axios'
import Select from 'react-select'
import 'react-notifications/lib/notifications.css';
import appleId from '../src/appleid_button.png'
import { NotificationContainer, NotificationManager } from 'react-notifications';
function App() {
    const optionsSchool = [
        {value:'VT', label: "Sở Vũ Tàu"},
        {value:'HN', label: 'Sở Hà Nội'},
        { value: 'HCM', label: 'Sở Hồ Chí Minh' }
    ]

    const [hcmlogin, setHcmLogin] = useState(false)
    const [options, setOptions] = useState([])
    const [schoolid, setSchoolid] = useState(-1)
    const [so,setSo] = useState({value:'VT', label: "Sở Vũ Tàu"})
    useEffect(() => {
        axios.get('https://wapi.hcm.edu.vn/ChuyenTruongMoRong/wapiquanly/getDonVi').then(res => {
            let _options = []
            console.log(res.data.Result.length);
            for (let school of res.data.Result) {
                _options.push({ value: school.SchoolID, label: school.TenTruong })

            }
            setOptions(_options)
        })
    }, [])
    const handle_hs_login = () => {
        axios.post('https://sso.megaschool.edu.vn/ssohcm/login', {
            "isHocSinh": true,
            "param1": ""
        }).then(response => {
            let url = response.data.Result
            console.log(url)
            window.location.href = url
        }).catch(err => {
            NotificationManager.error('Đăng nhập thất bại');
        })
    }
    const handle_gv_login = () => {
        if (schoolid === -1) {
            NotificationManager.error('Xin hãy chọn cơ sở!');
            return
        }
        axios.post('https://sso.megaschool.edu.vn/ssohcm/login', {
            "isHocSinh": false,
            "param1": schoolid
        }).then(response => {
            let url = response.data.Result
            console.log(url)
            window.location.href = url
        }).catch(err => {
            NotificationManager.error('Đăng nhập thất bại');
        })
    }
    return (
        <div className="content">
            <div className="boxbox">
                <div class="icon_fly_1"></div>
                <img src="logo.svg" className="logo" />
                <h3>Chọn phương thức đăng nhập bên dưới</h3>
                {!hcmlogin && <div className="buttonbox">
                    <p>
                        <a className="pure-button pure-button-primary pure-button-google" href="https://online.megaschool.edu.vn/login/google">
                            <span class="icon-google"></span>
                            <span>Đăng nhập Google</span>
                        </a>
                    </p>
                    <p>
                        <a className="pure-button pure-button-primary pure-button-microsoft" href="https://online.megaschool.edu.vn/login/microsoft">
                            <span class="icon-windows"></span>
                            <span>Đăng nhập Microsoft</span>
                        </a>
                    </p>
                    <p>
                        <a style={{width:'100%'}} href={'https://online.megaschool.edu.vn/login/apple'}>
                            <img style={{width:'100%'}} src={appleId}/>
                        </a>

                    </p>

                    <p>
                        {/* <btn className="pure-button pure-blue" onClick={(event) => { setHcmLogin(true) }}>Đăng nhập Sở Giáo dục</btn> */}
                        <a className="pure-button pure-blue" href={'https://online.megaschool.edu.vn/login/openid_connect?hcm=true'} >Đăng nhập Sở GD&ĐT</a>
                    </p>
                </div>}
                {hcmlogin && <div className="buttonbox">
                    <Select options={optionsSchool}
                            isLoading={true}
                            placeholder="Chọn Sở Giáo Dục"
                            defaultValue={{value:'VT', label:"Sở Vũng Tàu"}}
                            onChange={(option)=>{setSo(option)}}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary: 'black',
                                },
                            })}
                    />
                    <br />
                    {/*{so.value == 'HCM' &&<Select options={options}*/}
                    {/*    isLoading={true}*/}
                    {/*    onChange={(option) => { setSchoolid(option.value) }}*/}
                    {/*    placeholder="Chọn trường"*/}
                    {/*/>}*/}

                    {/*{so.value=='HCM' && <div><p><button className="pure-button pure-blue" onClick={() => { handle_hs_login() }} >Học sinh đăng nhập</button></p>*/}
                    {/*    <p><button className="pure-button pure-blue" onClick={() => { handle_gv_login() }}>Giáo viên đăng nhập</button></p></div>}*/}
                    {
                        (so.value=='HCM'  )  && <p><a className="pure-button pure-blue" href={'https://online.megaschool.edu.vn/login/openid_connect'} >Đăng nhập</a></p>
                    }
                    {
                        (so.value=='VT' || so.value=='HN' )  && <p><a className="pure-button pure-blue" href={'https://online.megaschool.edu.vn/login/canvas?login=admin'} >Đăng nhập</a></p>
                    }
                    <p><button className="pure-button pure-default" onClick={(event) => { setHcmLogin(false) }}>Quay lại</button></p>
                </div>}
                <div class="icon_fly"></div>
                <div class="icon_fly_2"></div>
            </div>
            <NotificationContainer />
        </div>
    );
}

export default App;
